// import fingerprint from "../assets/Fingerprint.svg";
// import securedPassword from "../assets/SecuredPassword.svg";
import faceId from "../assets/Face ID.svg";
import dataSecurity from "../assets/DataSecurity.svg";
import protectedPin from "../assets/ProtectedPin.svg";
import nearbyPayment from "../assets/nearbyPayment.svg";
import tapToPay from "../assets/tapToPay.svg"

export const CARD_DETAILS = [
  {
    id: "1",
    title: "Tap To Pay",
    description:
      "Blink lets you use your smartphone to pay by just tapping it wherever they accept NFC payments. This makes buying stuff in stores fast and safe without the need of a debit or credit card.",
    bgColor: "bg-[#E7FFC8]",
    image: {
      src: tapToPay,
      alt: "Tap To Pay",
    },
  },
  {
    id: "2",
    title: "Make Nearby Payments",
    description:
      "Use Geolocation to help you locate nearby merchants just within a few meters. Select the one you wish to pay and the merchant accepts your payment.",
    bgColor: "bg-[#ECD9FF]",
    image: {
      src: nearbyPayment,
      alt: "Make Nearby Payments",
    },
  },
];

export const SECURITY_CARD_DETAILS = [
  {
    id: "1",
    title: "Data Security",
    description:
      "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
    image: {
      src: dataSecurity,
      alt: "Data Security",
    },
  },
  {
    id: "2",
    title: "Protected Pin",
    description:
      "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
    image: {
      src: protectedPin,
      alt: "Protected Pin",
    },
  },
  {
    id: "3",
    title: "Biometrics",
    description:
      "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
    image: {
      src: faceId,
      alt: "Biometrics",
    },
  },

  // {
  //   id: "1",
  //   title: "Face Identification",
  //   description:
  //     "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
  //   image: {
  //     src: faceId,
  //     alt: "Face Identification",
  //   },
  // },
  // {
  //   id: "2",
  //   title: "Fingerprint",
  //   description:
  //     "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
  //   image: {
  //     src: fingerprint,
  //     alt: "Fingerprint",
  //   },
  // },
  // {
  //   id: "3",
  //   title: "Data Security",
  //   description:
  //     "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
  //   image: {
  //     src: dataSecurity,
  //     alt: "Data Security",
  //   },
  // },
  // {
  //   id: "4",
  //   title: "Secured Password",
  //   description:
  //     "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
  //   image: {
  //     src: securedPassword,
  //     alt: "Secured Password",
  //   },
  // },
  // {
  //   id: "5",
  //   title: "Protected Pin",
  //   description:
  //     "Pay with ease and take full control of your action with confidence in anywhere, everywhere and anytime.",
  //   image: {
  //     src: protectedPin,
  //     alt: "Protected Pin",
  //   },
  // },
];

export const FAQs_DETAILS = [
  {
    id: "1",
    question: "What is Blink?",
    answer:
      "Blink is a cutting-edge data payment system that allows you to make and receive payments, between businesses and customers through an advanced tech called Near Field Communication– all of this at the blink of an eye!",
  },
  {
    id: "2",
    question: "How does NFC works?",
    answer:
      "Near Field Communication is a wireless technology that allows two devices to communicate. NFC-enabled payment apps, like Blink, allows your phone to communicate with the receiver and thus initiates payment by sharing encrypted information within seconds.",
  },
  {
    id: "3",
    question: "Does Blink accept all cards?",
    answer:
      "Blink works with major debit cards such as Verve, Visa, and MasterCard, offering customers flexibility and convenience and continuously integrating more banks into its network.",
  },
  {
    id: "4",
    question: "What support options does Blink offer to its customers?",
    answer:
      "Blink provides dedicated customer support channels, including email, phone, and live chat, guaranteeing swift assistance and resolution for any inquiries or challenges encountered throughout transactions or integration processes.",
  },
];

export const REVIEWS_DETAILS = [
  {
    id: 1,
    abbr: "AO",
    name: "Adamu Oghale",
    role: "Sales Rep",
    description:
      "“Blink has made my life absolutely easy, no more long payment queues at the store, just tap and go.”",
  },
  {
    id: 2,
    abbr: "AO",
    name: "Adamu Oghale",
    role: "Sales Rep",
    description:
      "“Blink has made my life absolutely easy, no more long payment queues at the store, just tap and go.”",
  },
  {
    id: 3,
    abbr: "AO",
    name: "Adamu Oghale",
    role: "Sales Rep",
    description:
      "“Blink has made my life absolutely easy, no more long payment queues at the store, just tap and go.”",
  },
  {
    id: 4,
    abbr: "AO",
    name: "Adamu Oghale",
    role: "Sales Rep",
    description:
      "“Blink has made my life absolutely easy, no more long payment queues at the store, just tap and go.”",
  },
  {
    id: 5,
    abbr: "AO",
    name: "Adamu Oghale",
    role: "Sales Rep",
    description:
      "“Blink has made my life absolutely easy, no more long payment queues at the store, just tap and go.”",
  },
];
