import { RouterProvider, createBrowserRouter } from "react-router-dom";

import RootLayout from "./pages/Root";
import HomePage from "./pages/Home";
import { ContactUs } from "./pages/ContactUs";
import { FAQs } from "./components/FAQ/FAQs";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "/contact-us", element: <ContactUs /> },
      { path: "/faq", element: <FAQs /> },
    ],
  },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
