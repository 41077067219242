import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import ReviewsItem from "./ReviewsItem";
import { REVIEWS_DETAILS } from "../../data/DUMMY_DATA";

const responsive = {
  desktop: {
    breakpoint: { max: 4000, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 639 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1,
  },
};

const Reviews = () => {
  const CarouselSection = REVIEWS_DETAILS.map((carousel) => (
    <ReviewsItem
      key={carousel.id}
      abbr={carousel.abbr}
      name={carousel.name}
      role={carousel.role}
      description={carousel.description}
    />
  ));

  return (
    <section className="pb-20 sm:pb-28 flex flex-col items-center justify-center">
      <div className="text-center wow animate__animated animate__fadeInUp max-w-[658px] sm:max-w-[600px]">
        <h1 className="font-bold text-[41px] sm:text-[65px] lg:text-5xl text-[#032247] px-3 smPro:px-4">
          Here’s what our Users say
        </h1>
        <p className="font-normal text-xl sm:text-3xl lg:text-2xl py-10 text-[#2d4766] px-6">
          Blink works in-store, in-app and online. Load your cards onto your
          devices and check out with a tap.
        </p>
      </div>
      <Carousel
        responsive={responsive}
        className="w-[340px] smPro:w-[390px] sm:w-[640px] md:w-[765px] lg:w-[1000px] xl:w-full"
      >
        {CarouselSection}
      </Carousel>
    </section>
  );
};

export default Reviews;
