const ReviewsItem = ({ abbr, name, role, description }) => {
  return (
    <section className="pb-4">
      <div className="bg-white shadow-lg p-4 mx-4 rounded-xl">
        <div className="flex items-center mb-4">
          <h1 className="bg-[#05204A] text-white rounded-full font-bold p-4 mr-2">
            {abbr}
          </h1>
          <div>
            <h3 className="font-semibold text-xl">{name}</h3>
            <p className="font-normal text-sm">{role}</p>
          </div>
        </div>

        <p className="leading-7">{description}</p>
      </div>
    </section>
  );
};

export default ReviewsItem;
