import { FAQs_DETAILS } from "../../data/DUMMY_DATA";
import { FAQsItem } from "./FAQsItem";
import Footer from "../../common/Footer";

export const FAQs = () => {
  const FAQsList = FAQs_DETAILS.map((faq) => (
    <FAQsItem
      id={faq.id}
      key={faq.id}
      question={faq.question}
      answer={faq.answer}
    />
  ));
  return (
    <>
      <section className="font-jakarta flex justify-center items-center pb-28 bg-slate-50 h-screen">
        <div>
          <div className="text-center py-8 wow animate__animated animate__fadeInUp">
            <h1 className="text-4xl sm:text-5xl lg:text-[55px] font-bold text-[#032247]">
              Frequently Asked Questions
            </h1>
            <h6 className="text-lg font-semibold text-[#2d4766] pt-2 smPro:px-6 sm:px-0">
              Got Questions? Answers and advice from the Blink Team
            </h6>
          </div>
          {FAQsList}
        </div>
      </section>

      <Footer />
    </>
  );
};
