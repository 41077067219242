import { useRef } from "react";

import { IPhone, PlayStore, Android } from "../svg/svgs";
import barcode from "../assets/barcode.png";

export default function DownloadApp() {
  const dialogRef = useRef();

  const toggleModalHandler = () => {
    dialogRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const toggleBackdropHandler = () => {
    dialogRef.current.close();
    document.body.style.overflow = "";
  };
  return (
    <>
      <div onClick={toggleModalHandler}>
        <button className="flex p-3.5">
          <div className="pr-2">
            <IPhone />
          </div>
          |
          <div className="pl-2 pr-3">
            <PlayStore />
          </div>
          <h1 className="font-medium font-jakarta">Download App</h1>
        </button>
      </div>

      <dialog
        ref={dialogRef}
        className="backdrop:bg-slate-50 rounded-2xl shadow-2xl"
        onClick={toggleBackdropHandler}
      >
        <div className="bg-white max-w-[300px] smPro:max-w-[340px] sm:max-w-[400px]">
          <div className="flex items-center justify-center py-4">
            <div className="flex bg-slate-100 p-2 rounded-xl">
              <div className="pr-4">
                <IPhone />
              </div>
              <Android />
            </div>
          </div>

          <div className="text-center px-6">
            <h1 className="text-xl font-bold text-[#032247]">
              Scan to download
            </h1>
            <p className="text-xs font-medium text-[#2d4766] py-4">
              Position your phone camera within the frame and follow the link to
              download.
            </p>
            <div className="flex items-center justify-center">
              <img src={barcode} alt="Barcode" />
            </div>

            <p className="text-xs font-medium text-[#2d4766] pb-6">
              Download the Blink app and Sign up or log in with your account.
            </p>
          </div>
        </div>
      </dialog>
    </>
  );
}
