export const Hamburger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 24 24"
    >
      <path
        fill="#032247"
        d="M4 17.27v-1h16v1zm0-4.77v-1h16v1zm0-4.77v-1h16v1z"
      />
    </svg>
  );
};

export const HamburgerClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 24 24"
    >
      <path
        fill="#032247"
        stroke="#032247"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"
      />
    </svg>
  );
};

export const IPhone = () => {
  return (
    <svg width="1em" height="1.5625em" viewBox="0 0 24 25" fill="none">
      <g clipPath="url(#apple-logo-clip-path)" fill="currentColor">
        <path d="M16.499.5c-1.28.088-2.775.907-3.646 1.974-.796.967-1.45 2.404-1.194 3.801 1.397.043 2.842-.795 3.679-1.88.783-1.009 1.376-2.437 1.161-3.895z"></path>
        <path d="M21.555 8.552c-1.228-1.54-2.955-2.435-4.585-2.435-2.153 0-3.063 1.03-4.559 1.03-1.542 0-2.713-1.027-4.575-1.027-1.828 0-3.775 1.118-5.01 3.029-1.735 2.69-1.438 7.75 1.374 12.06 1.007 1.542 2.35 3.276 4.109 3.29 1.564.016 2.005-1.003 4.125-1.013 2.12-.012 2.521 1.027 4.083 1.01 1.76-.013 3.177-1.934 4.183-3.476.722-1.106.99-1.662 1.55-2.91-4.07-1.55-4.722-7.337-.695-9.558z"></path>
      </g>
      <defs>
        <clipPath id="apple-logo-clip-path">
          <path
            fill="currentColor"
            transform="translate(0 .5)"
            d="M0 0h24v24H0z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const PlayStore = () => {
  return (
    <svg width="1em" height="1.5625em" viewBox="0 0 24 25" fill="none">
      <g clipPath="url(#play-store-logo-clip-path)">
        <path
          d="M4.66955 0.877687C4.1228 0.52439 3.46608 0.417609 2.8501 0.563015L13.675 11.388L17.1834 7.87959L4.66955 0.877687Z"
          fill="currentColor"
        ></path>
        <path
          d="M1.50669 1.44287C1.20477 1.84426 1.03101 2.33711 1.03101 2.85845V22.1409C1.03101 22.6622 1.20482 23.155 1.50669 23.5564L12.5634 12.4997L1.50669 1.44287Z"
          fill="currentColor"
        ></path>
        <path
          d="M21.7623 10.4415L18.6092 8.67725L14.7869 12.4996L18.6094 16.3222L21.7628 14.5578C22.5183 14.1345 22.9693 13.3652 22.9693 12.4996C22.9693 11.634 22.5183 10.8648 21.7623 10.4415Z"
          fill="currentColor"
        ></path>
        <path
          d="M13.6751 13.6116L2.85083 24.4359C3.0283 24.4776 3.20891 24.5001 3.38952 24.5001C3.83586 24.5001 4.28066 24.3732 4.66967 24.1218L17.1837 17.12L13.6751 13.6116Z"
          fill="currentColor"
        ></path>
      </g>
      <defs>
        <clipPath id="play-store-logo-clip-path">
          <rect
            width="24"
            height="24"
            fill="currentColor"
            transform="translate(0 0.5)"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
};

export const Android = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="black"
        d="M17.532 15.106a1.003 1.003 0 1 1 .001-2.007a1.003 1.003 0 0 1 0 2.007m-11.044 0a1.003 1.003 0 1 1 .001-2.007a1.003 1.003 0 0 1 0 2.007m11.4-6.018l2.006-3.459a.413.413 0 1 0-.721-.407l-2.027 3.5a12.243 12.243 0 0 0-5.13-1.108c-1.85 0-3.595.398-5.141 1.098l-2.027-3.5a.413.413 0 1 0-.72.407l1.995 3.458C2.696 10.947.345 14.417 0 18.523h24c-.334-4.096-2.675-7.565-6.112-9.435"
      />
    </svg>
  );
};

export const ArrowRight = () => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="new-arrow-right "
    >
      <path
        d="M9.1497 0.80204C9.26529 3.95101 13.2299 6.51557 16.1451 8.0308L16.1447 9.43036C13.2285 10.7142 9.37889 13.1647 9.37789 16.1971L7.27855 16.1978C7.16304 12.8156 10.6627 10.4818 13.1122 9.66462L0.049716 9.43565L0.0504065 7.33631L13.1129 7.56528C10.5473 6.86634 6.93261 4.18504 7.05036 0.80273L9.1497 0.80204Z"
        fill="white"
      ></path>
    </svg>
  );
};

export const Loading = () => {
  return (
    <svg
      className="w-[1em] h-[1em] animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        d="M304 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm0 416a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM48 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm464-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM142.9 437A48 48 0 1 0 75 369.1 48 48 0 1 0 142.9 437zm0-294.2A48 48 0 1 0 75 75a48 48 0 1 0 67.9 67.9zM369.1 437A48 48 0 1 0 437 369.1 48 48 0 1 0 369.1 437z"
        fill="white"
      />
    </svg>
  );
};

export const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="#2d4766"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m7 10l5 5l5-5"
      />
    </svg>
  );
};

export const ArrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="none"
        stroke="#2d4766"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m17 14l-5-5l-5 5"
      />
    </svg>
  );
};

export const ArrowSide = () => {
  return (
    <svg viewBox="0 0 25 25" fill="none" class="w-5 lg:w-6.25">
      <path
        d="M8 18L18 8"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M8 8H18V18"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};

export const Quote = () => {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      class="mb-7.5 h-6 sm:h-8 lg:h-10 w-6 sm:w-8 lg:w-10"
    >
      <path
        d="M20.2722 9H12.2458C11.477 9 10.7763 9.44069 10.4432 10.1336L1.37767 28.9954C0.739517 30.3231 1.70713 31.8617 3.18028 31.8617H7.29599C7.95436 31.8617 8.57056 31.5377 8.94372 30.9953L21.92 12.1336C22.8328 10.8066 21.8829 9 20.2722 9Z"
        fill="#032247"
      ></path>
      <path
        d="M36.1961 9H28.1696C27.4008 9 26.7001 9.44069 26.367 10.1336L17.3015 28.9954C16.6633 30.3231 17.631 31.8617 19.1041 31.8617H23.2198C23.8782 31.8617 24.4944 31.5377 24.8675 30.9953L37.8438 12.1336C38.7567 10.8066 37.8067 9 36.1961 9Z"
        fill="#032247"
      ></path>
    </svg>
  );
};
