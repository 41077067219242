import { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

import { ArrowRight, Loading } from "../svg/svgs";
import { useDispatch, useSelector } from "react-redux";
import {
  setEmail,
  setEmailIsValid,
  setEmailIsEmpty,
  setSuccessMessage,
  setMessageTimerId,
  setDisplayRecaptcha,
  setLoading,
  setError,
} from "../state/emailValidationState";

export default function EmailValidation() {
  const dispatch = useDispatch();
  const {
    email,
    emailIsValid,
    emailIsEmpty,
    successMessage,
    messageTimerId,
    displayRecaptcha,
    loading,
    error,
  } = useSelector((state) => state.emailValidation);

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;

    dispatch(setEmail(newEmail));

    dispatch(setEmailIsValid(validateEmail(newEmail)));

    dispatch(setEmailIsEmpty(capitalizeFirstLetter(newEmail)));

    if (newEmail.trim() !== "") {
      dispatch(setEmailIsEmpty(""));
      dispatch(setError(""));
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  let newMessageTimerId;

  const handleRecaptchaError = () => {
    dispatch(
      setError("Unable to connect. Please check your internet connection.")
    );
    dispatch(setEmail(""));
    dispatch(setLoading(false));
    dispatch(setDisplayRecaptcha(false));
  };

  const handleRecaptchaExpired = () => {
    dispatch(setError("Session expired. Please try again."));
    dispatch(setEmail(""));
    dispatch(setLoading(false));
    dispatch(setDisplayRecaptcha(false));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (email.trim() === "") {
      dispatch(
        setEmailIsEmpty(capitalizeFirstLetter("email is a required field"))
      );
      return;
    }
    dispatch(setLoading(true));

    dispatch(setDisplayRecaptcha(true));
  };

  const handleRecaptchaSubmit = async () => {
    const response = await axios
      .post(`${process.env.REACT_APP_API_URL}`, { email })
      .then((res) => res.data)
      .catch((error) => error.response.data);

    // To validate if the email address has been inputted before or not
    if (String(response.statusCode).includes(4)) {
      dispatch(setError(response.message));
      dispatch(setDisplayRecaptcha(false));
      dispatch(setLoading(false));
      newMessageTimerId = setTimeout(() => {
        dispatch(setError(""));
        dispatch(setEmail(""));
      }, 3000);
      dispatch(setMessageTimerId(newMessageTimerId));
      return;
    }

    dispatch(setSuccessMessage(response.data.message));
    dispatch(setEmail(""));
    dispatch(setDisplayRecaptcha(false));
    dispatch(setLoading(false));

    newMessageTimerId = setTimeout(() => {
      dispatch(setSuccessMessage(""));
    }, 3000);
    dispatch(setMessageTimerId(newMessageTimerId));
  };

  useEffect(() => {
    return () => {
      clearTimeout(messageTimerId);
    };
  }, [messageTimerId]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center items-center relative">
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleEmailChange}
            className="bg-white w-full pl-4 py-3 sm:py-4 outline-none rounded-3xl relative"
          />
          <button
            type="submit"
            className="absolute right-0 bg-black rounded-full mr-2 p-2"
          >
            {loading ? <Loading /> : <ArrowRight />}
          </button>
        </div>

        {displayRecaptcha && (
          <div className="absolute scale-[0.8] smPro:mt-2 smPro:transform-none left-0 right-20 smPro:left-[unset] smPro:right-[unset]">
            <ReCAPTCHA
              sitekey={`${process.env.REACT_APP_RECAPTCHA_KEY}`}
              onChange={handleRecaptchaSubmit}
              onErrored={handleRecaptchaError}
              onExpired={handleRecaptchaExpired}
            />
          </div>
        )}

        {!emailIsValid && (
          <p className="absolute text-red-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            Invalid email format
          </p>
        )}

        {emailIsEmpty && (
          <p className="absolute text-red-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            {capitalizeFirstLetter(emailIsEmpty)}
          </p>
        )}

        {error ? (
          <p className="absolute text-red-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            {error}
          </p>
        ) : (
          <p className="absolute text-green-600 text-base smPro:text-lg sm:text-lg md:text-lg font-semibold">
            {successMessage}
          </p>
        )}
      </form>
    </>
  );
}
