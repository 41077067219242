// import WaitList from "../components/WaitList";
import logo from "../assets/logo1.svg";
import twitter from "../assets/Twitter.svg";
import facebook from "../assets/Facebook.svg";
import linkedIn from "../assets/LinkedIn.svg";
import DownloadApp from "../components/DownloadApp";

const currentDate = new Date().getFullYear();

export default function Footer() {
  return (
    <>
      <div className="font-jakarta relative bg-[#032247] w-full pb-10">
        <div className="max-w-[1140px] m-auto">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center py-10 px-6 sm:px-16 md:px-10 xl:px-0">
            <div className="flex justify-between items-center md:w-[54%]">
              <img
                src={logo}
                alt="Logo"
                className="h-[74px] sm:h-28 md:h-16 lg:h-20"
              />

              <div className="flex justify-between w-32 smPro:w-[150px] sm:w-44 md:w-[160px]">
                <img
                  src={twitter}
                  alt="Twitter Icon"
                  className="sm:h-10 md:w-10"
                />
                <img
                  src={facebook}
                  alt="Facebook Icon"
                  className="sm:h-10 md:w-10"
                />
                <img
                  src={linkedIn}
                  alt="LinkedIn Icon"
                  className="sm:h-10 md:w-10"
                />
              </div>
            </div>

            <div className="flex items-center justify-center border rounded-full text-white mt-10 md:mt-0">
              <DownloadApp />
            </div>
          </div>

          <hr className="bg-[#2F4568]" />

          <h2 className="text-center text-lg text-[#cdd3da] pt-10 sm:pt-16 lg:pt-8">
            &copy; {currentDate} Blink HQ. All rights reserved
          </h2>
        </div>

        {/* <div className="absolute bottom-[84%] md:bottom-[79%] lg:bottom-[85%] w-[100%]">
          <div className="max-w-[1140px] m-auto">
            <WaitList />
          </div>
        </div> */}
      </div>
    </>
  );
}
