import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  email: "",
  emailIsValid: true,
  emailIsEmpty: "",
  successMessage: "",
  messageTimerId: null,
  displayRecaptcha: false,
  loading: false,
  error: "",
};

const emailValidationState = createSlice({
  name: "emailValidation",
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },

    setEmailIsValid(state, action) {
      state.emailIsValid = action.payload;
    },

    setEmailIsEmpty(state, action) {
      state.emailIsEmpty = action.payload;
    },

    setSuccessMessage(state, action) {
      state.successMessage = action.payload;
    },

    setMessageTimerId(state, action) {
      state.messageTimerId = action.payload;
    },

    setDisplayRecaptcha(state) {
      state.displayRecaptcha = !state.displayRecaptcha;
    },

    setLoading(state) {
      state.loading = !state.loading;
    },

    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const {
  setEmail,
  setEmailIsValid,
  setEmailIsEmpty,
  setSuccessMessage,
  setMessageTimerId,
  setDisplayRecaptcha,
  setLoading,
  setError,
} = emailValidationState.actions;

export default emailValidationState.reducer;
