import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { Hamburger, HamburgerClose } from "../../svg/svgs";
import Nav from "./Nav";
import logo from "../../assets/logo2.svg";

export default function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [navbarBgColor, setNavbarBgColor] = useState(false);

  const toggleNavbar = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const changeBgColor = () => {
    if (window.scrollY >= 80) {
      setNavbarBgColor(true);
    } else {
      setNavbarBgColor(false);
    }
  };

  window.addEventListener("scroll", changeBgColor);

  return (
    <header
      className={`${
        navbarBgColor ? "bg-white shadow-xl" : "bg-slate-50"
      } sticky top-0 z-[20]`}
    >
      <section className="max-w-[1200px] m-auto font-jakarta">
        <div className="flex justify-between items-center lg:mr-6">
          <div className="flex justify-between w-full lg:w-[100px] py-2 px-2 sm:px-16 lg:px-6 xl:px-8">
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                className="h-14 sm:h-16 mr-20"
                onClick={() => {
                  setMenuIsOpen(!menuIsOpen);
                }}
              />
            </Link>

            <div onClick={toggleNavbar} className="lg:hidden">
              {menuIsOpen ? <HamburgerClose /> : <Hamburger />}
            </div>
          </div>

          <div className="hidden lg:flex">
            <Nav />
          </div>
        </div>
      </section>

      <div
        className={`lg:hidden absolute w-full  ${
          navbarBgColor ? "bg-white" : "bg-slate-50"
        }`}
      >
        <div className={menuIsOpen ? "h-screen" : ""}>
          {menuIsOpen && (
            <>
              <nav className="font-medium text-sm lg:text-[15px] text-[#2D4766]">
                <ul className="flex flex-col lg:flex-row justify-center items-center">
                  <li className="mb-8 lg:mb-0 cursor-pointer">
                    {/* <NavLink
                      to="/business"
                      className={({ isActive }) =>
                        isActive ? "text-[#032247]" : undefined
                      }
                      onClick={() => {
                        setMenuIsOpen(!menuIsOpen);
                      }}
                    > */}
                    Blink for Business
                    {/* </NavLink> */}
                  </li>

                  <li className="mb-8 lg:mb-0 cursor-pointer">
                    <NavLink
                      to="/faq"
                      className={({ isActive }) =>
                        isActive ? "relative text-[#032247]" : undefined
                      }
                      onClick={() => {
                        setMenuIsOpen(!menuIsOpen);
                      }}
                    >
                      {({ isActive }) => (
                        <>
                          FAQ
                          {isActive && (
                            <span className="w-2 h-2 bg-[#032247] absolute  rounded-full left-[43%] top-[120%]"></span>
                          )}
                        </>
                      )}
                    </NavLink>
                  </li>

                  <li className="mb-8 lg:mb-0 cursor-pointer">
                    <NavLink
                      to="/contact-us"
                      className={({ isActive }) =>
                        isActive ? "relative text-[#032247]" : undefined
                      }
                      onClick={() => {
                        setMenuIsOpen(!menuIsOpen);
                      }}
                    >
                      {({ isActive }) => (
                        <>
                          Contact Us
                          {isActive && (
                            <span className="w-2 h-2 bg-[#032247] absolute  rounded-full left-[43%] top-[120%]"></span>
                          )}
                        </>
                      )}
                    </NavLink>
                  </li>

                  <li>
                    <button className="border rounded-full py-3 px-6 text-white bg-[#032247] font-normal mb-8 lg:mb-0">
                      Get started
                    </button>
                  </li>
                </ul>
              </nav>
            </>
          )}
        </div>
      </div>
    </header>
  );
}
