import checkOut from "../assets/checkOut.svg"

const CheckOut = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center mb-28">
      <div className="max-w-[658px] wow animate__animated animate__fadeInUp">
        <h1 className="font-bold text-[41px] sm:text-[65px] lg:text-5xl text-[#032247] px-3 smPro:px-4">
          Check out fast
        </h1>
        <p className="font-normal text-xl sm:text-3xl lg:text-2xl py-10 text-[#2d4766] px-6">
          Blink works in-store, in-app and online. Load your cards onto your
          devices and check out with a tap.
        </p>
      </div>

      <div className="px-4 md:px-10 xl:px-0 wow animate__animated animate__zoomIn">
        <img src={checkOut} alt="Check out fast" />
      </div>
    </div>
  );
};

export default CheckOut;
